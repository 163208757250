
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBody',
  props: {
    msg: String
  },
  data () {
    return {
      text: '',
      decodedText: '...'
    }
  },
  methods: {
    urlDecode () {
      this.decodedText = decodeURIComponent(this.text)
    }
  }
})
