<template>
  <footer>
    <p>
      <a href="https://ulidgenerator.back-end.info/">ulid</a> ·
      <a href="https://uuidgenerator.back-end.info/">uuid</a> ·
      <a href="https://unixtimestamp.back-end.info/">unix timestamp</a> ·
      <a href="https://urldecode.back-end.info/">url decode</a> ·
      <a href="https://urlencode.back-end.info/">url encode</a> |
      Copyright &copy; {{ year }}
    </p>
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>

<style scoped lang="scss">
footer {
  p {
    margin: 100px 10px;
    font-size: 12px;
  }
}
</style>
